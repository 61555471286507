<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <b-row>
      <b-col sm="12" md="4" class="mb-3">
        <b-card header="Aluguéis">
          <b-list-group>
            <b-list-group-item :to="{ name: 'ReportRents' }">
              <span class="fas fa-user-clock mr-2" />Aluguéis Geral
            </b-list-group-item>
            <b-list-group-item :to="{ name: 'ReportRentsPerToy' }">
              <span class="fas fa-user-clock mr-2" />Aluguéis por Brinquedo
            </b-list-group-item>
            <b-list-group-item :to="{ name: 'ReportRentsPerUser' }">
              <span class="fas fa-user-clock mr-2" />Aluguéis por Usuário
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col sm="12" md="4" class="mb-3">
        <b-card header="Vendas">
          <b-list-group>
            <b-list-group-item :to="{ name: 'ReportProductsPayment' }">
              <span class="fas fa-cash-register mr-2" />Produtos
            </b-list-group-item>
            <b-list-group-item :to="{ name: 'ReportPaymentMethods' }">
              <span class="fas fa-cash-register mr-2" />Formas de pagamentos
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col sm="12" md="4">
        <b-card header="Outros Relatórios">
          <b-list-group>
            <b-list-group-item :to="{ name: 'ReportCheckouts' }">
              <span class="fas fa-cash-register mr-2" />Caixas
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'

export default {
  name: 'ReportsIndexView',
  components: {
    Layout
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Relatórios',
        to: { name: 'ReportsIndex' },
        active: true
      }
    ]
  }
}
</script>
